<template>
  <div class="main_right">
    <div class="Box">
      <!-- 选择 -->
      <div class="select">
        <el-select v-model="currentJxid" @change="selectChange(currentJxid)" class="selsectbox" style="width:40%;height:2vw;float:left;" placeholder="请选择">
          <el-option v-for="item in options" :key="item.jxid" :label="item.jxmc" :value="item.jxid" />
        </el-select>
        <p>
          {{selectObj.yxqq}}——{{selectObj.yxqz}}
        </p>
      </div>
      <div class="aRow">
        <!-- 词汇量 -->
        <div class="vocabulary">
          <div>
            <span>我的词汇量</span>
            <p>
              <span>{{ vocabulary }}</span>
              <span>个</span>
            </p>
          </div>
        </div>
      </div>
      <div class="testpage" style="height:calc(100% - 6.5vw);">
        <p>
          <span>我的测试</span>
        </p>
        <el-table ref="table" class="table" :data="tableData" border :header-cell-style="{background:'#F1F8FF',color:'#333','text-align':'center'}">
          <el-table-column label="日期" align="center">
            <template v-slot="scope">
              {{ scope.row.cjrq ? scope.row.cjrq.slice(0, 10) : '暂未完成' }}
            </template>
          </el-table-column>
          <el-table-column label="测试名称" align="center">
            <template v-slot="scope">
              {{ scope.row.lx ? '结业测试' : '入学测试' }}
            </template>
          </el-table-column>
          <el-table-column label="用时" align="center">
            <template v-slot="scope">
              {{ parseInt(scope.row.ys) + '秒' }}
            </template>
          </el-table-column>
          <el-table-column label="词汇量" align="center">
            <template v-slot="scope">
              {{ Number(scope.row.ygchl) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <span class="operation" v-if="scope.row.id" @click="look(scope.row)">查看成绩</span>
              <span @click="link(scope.row.lx)" class="operation" style="background:#5990FF;color:#fff;" v-if="!scope.row.id && ((tableData[0].id && scope.row.lx) || !scope.row.lx)">立即完成</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="exitpopup animate__animated animate__fadeInDown" v-if="exitPopup">
      <!-- 查看成绩弹窗 -->
      <div class="viewRresults">
        <div class="title">成绩单</div>
        <div class="body">
          <!-- 题目数量 -->
          <div class="box">
            <p style="color:#5990FF;">{{cjdObj.dtsl}}</p>
            <p>题目数量</p>
          </div>
          <!-- 正确率 -->
          <div class="box">
            <p style="color:#30DA1F;">{{cjdObj.zql}}%</p>
            <p>正确率</p>
          </div>
          <!-- 词汇量 -->
          <div class="box">
            <p style="color:#30DA1F;">{{cjdObj.ygchl}}</p>
            <p>词汇量</p>
          </div>
        </div>
        <div class="footer">
          <p @click="disappear">我知道了</p>
        </div>
      </div>
      <!-- 查看成绩遮罩层 -->
      <div class="mask" @click="disappear"></div>
    </div>
  </div>
</template>
<script>
import { getTrains, getTestList } from '@/api/student'
export default {
  data() {
    return {
      currentJxid: null, //select选择器
      tableData: [],
      options: [],
      selectObj: {}, //下拉框选中的数据
      vocabulary: 0, // 词汇量
      exitPopup: false, // 查看成绩弹窗v-model
      cjdObj: {}, // 查看成绩数据
      // 当前展示的集训基本信息
      selectJxInfor: {},
    }
  },
  created() {
    getTrains({ yhid: this.$store.state.userInfo.yhid }).then(res => {
      if (res.success) {
        if (res.result.length) {
          this.options = res.result
          this.currentJxid = this.options[0].jxid
          this.selectObj = this.options[0];
          this.selectJxInfor = this.options[0];
          console.log(this.selectJxInfor)
        } else {
          this.$message.warning('未参加集训，暂无数据')
        }
      } else {
        this.$message.error(res.resultDesc)
      }
    })
  },
  watch: {
    currentJxid(newV, oldV) {
      // getTestList({ cjid: newV, xsid: this.$store.state.userInfo.yhid }).then(res => {
      //   if (res.success) {
      //     this.vocabulary = Number(res.result.chl)
      //     this.tableData = [
      //       { cjrq: '', lx: 0, ys: 0, fs: 0, ygchl: 0 },
      //       { cjrq: '', lx: 1, ys: 0, fs: 0, ygchl: 0 }
      //     ]
      //     if (res.result.list && res.result.list.length) {
      //       for (let i = 0; i < res.result.list.length; i++) {
      //         this.tableData[i] = res.result.list[i]
      //       }
      //     } else {
      //       this.$message.warning('未进行测试，暂无数据')
      //     }
      //   } else {
      //     this.$message.error(res.resultDesc)
      //   }
      // })
    }
  },
  methods: {
    selectChange(val) {
      this.selectObj = this.options.find(item => {
        this.selectJxInfor = val;
        console.log(this.selectJxInfor)
        return item.jxid == val
      })
    },
    look(row) {
      this.cjdObj = row
      this.exitPopup = true
    },
    link(lx) {
      if(!sessionStorage.getItem('jxInfo')){
        this.$message.warning('当前暂无集训')
        return
      }
      const dataQuery = {
        testType: null,
        bjid: JSON.parse(sessionStorage.getItem('jxInfo')).bjid,
        jxid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        bjmc: JSON.parse(sessionStorage.getItem('jxInfo')).bjmc,//
      }
      if (lx) {
        // 跳到结业测试
        dataQuery.testType = 2
      } else {
        // 跳到入学测试
        dataQuery.testType = 1
      }
      this.$router.push({ name: 'totaltest', query: dataQuery })
    },
    disappear() {
      const oDom = document.querySelector('.exitpopup')
      oDom.classList.add('animate__animated', 'animate__slideOutDown')
      setTimeout(() => {
        this.exitPopup = false
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.main_right {
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .Box {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 2%;
    box-sizing: border-box;
    position: relative;
    .select {
      height: 2.5vw;
      width: 47vw;
      // background: #5990ff;
      background: #fff;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      padding: 0.25vw;
      margin-bottom: 1vw;
      p {
        float: right;
        width: 50%;
        height: 100%;
        background: #f1f8ff;
        font-size: 1vw;
        color: #333;
        text-align: center;
        line-height: 2vw;
        border: 1px solid rgb(220, 223, 230);
        border-radius: 4px;
      }
    }
  }
  .vocabulary {
    width: 30%;
    height: 3vw;
    float: left;
    div {
      width: 14vw;
      height: 3vw;
      background: #f1f8ff;
      border-radius: 10px;
      line-height: 3vw;
      box-sizing: border-box;
      padding: 0 3%;
      color: #333;
      span {
        display: block;
      }
      span:nth-child(1) {
        // display: block;
        font-size: 1.1vw;
        float: left;
      }
      p {
        float: right;
        span:nth-child(1) {
          font-size: 1.5vw;
          color: #409eff;
          font-weight: 600;
          float: left;
          margin-right: 1.5vw;
        }
        span:nth-child(2) {
          font-size: 1vw;
          float: left;
        }
      }
    }
  }
  .testpage {
    width: 100%;
    position: relative;
    p:nth-child(1) {
      width: 100%;
      height: 2.5vw;
      text-align: center;
      position: relative;
      color: #333;
      font-size: 1.5vw;
      line-height: 2.5vw;
    }
  }
  .table {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2vw;
    top: 4vw;
  }
  .aRow {
    width: 100%;
    height: 3vw;
  }
  .aRow:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .operation {
    display: inline-block;
    line-height: 2vw;
    width: 5vw;
    height: 2vw;
    font-size: 0.8vw;
    background: #e0eaff;
    box-shadow: 0px 0px 7px 1px rgba(89, 144, 255, 0.3);
    border-radius: 21px;
    color: #5990ff;
    cursor: pointer;
  }
  .selsectbox {
    width: 130px;
    // background: #fff;
    background: #f1f8ff;
    border-radius: 4px;
  }
  .exitpopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2004;
  }
  .viewRresults {
    width: 30vw;
    height: 14.5vw;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 2004;
    .title {
      width: 100%;
      height: 3vw;
      background: #5990ff;
      border-radius: 10px 10px 0 0;
      color: #fff;
      font-size: 1.3vw;
      text-align: center;
      line-height: 3vw;
    }
    .body {
      width: 100%;
      height: 8vw;
      box-sizing: border-box;
      padding: 1vw;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .box {
        width: 30%;
        height: 100%;
        background: #eaf0fc;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 1vw;
        position: relative;
        p:nth-child(2) {
          position: absolute;
          top: 3.5vw;
          font-size: 1.1vw;
        }
        p:nth-child(1) {
          font-size: 1.7vw;
        }
      }
    }
    .footer {
      width: 100%;
      height: 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: 8vw;
        height: 2.3vw;
        background: #5990ff;
        border-radius: 25px;
        color: #fff;
        font-size: 1.2vw;
        text-align: center;
        line-height: 2.3vw;
        cursor: pointer;
      }
    }
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2001;
  }
  //自定义el-input框的一些自定义样式
  /deep/ .selsectbox .el-input--small .el-input__inner {
    // background-color: #e6f0ff;
    padding-left: 40px;
    height: 50px;
    border-radius: 5px;
    // color: #1890FF;
    font-size: 18px;
    border: 1px solid #1890ff;
    font-weight: 600;
  }
  //自定义左边图标
  // .selsectbox /deep/.el-input__inner{
  //   background: url('../../../assets/images/student/left.png') no-repeat center center; //引入icon
  //   background-size: 18px 18px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
  //   background-position:0.5vw 50%; //在input中定位置  可以调整自定义icon的左右上下位置
  //   padding: 0 0 0 2.5vw; //需要设置padding 把placeholder向右移
  //   box-sizing: border-box;
  //   font-size: 14px;
  // }
  //替换右边边默认图标
  /deep/.selsectbox .el-icon-arrow-up::before {
    content: "";
    background: url("../../../assets/student/triangle.png") center center
      no-repeat;
    position: absolute;
    background-size: 18px 12px;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  /deep/ .selsectbox .el-input--small input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    // color: #2881FF;
  }
}
</style>